import React from 'react';
import LayoutDefault from '../../layouts/LayoutDefault';
import PageMetaData from '../../components/layout/PageMetaData';
import CandidatePolicy from '../../components/sections/candidate-policy/CandidatePolicy';
import { graphql } from 'gatsby';
import { AllJsonData } from '../../types/graphql';
import { PolicyContent } from '../../types/policy-content';

interface CandidatePolicyPageProps extends AllJsonData<PolicyContent, { allJson: [] }> {}

const CandidatePolicyPage: React.FC<CandidatePolicyPageProps> = ({ data }) => {
  const candidatePolicyContent = data?.allJson.nodes || [];

  return (
    <LayoutDefault>
      <PageMetaData title="Privacy Policy" />
      <CandidatePolicy policyContent={candidatePolicyContent} />
    </LayoutDefault>
  );
};

export const query = graphql`
  query CandidatePolicy {
    allJson(filter: { title: { eq: "candidatePolicyContent" } }) {
      nodes {
        contentEn
        contentLt
      }
    }
  }
`;

export default CandidatePolicyPage;
