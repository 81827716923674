import classNames from 'classnames';
import React, { useState } from 'react';
import { PolicyContent } from '../../../types/policy-content';
import { SectionTilesTypesProps } from '../../../utils/SectionProps';
import Button from '../../elements/Button';

interface CandidatePolicyProps extends SectionTilesTypesProps {
  className?: string;
  policyContent: PolicyContent[];
}

const defaultProps: CandidatePolicyProps = {
  topOuterDivider: false,
  bottomOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false,
  pushLeft: false,
  policyContent: [],
};

const PrivacyPolicy: React.FC<CandidatePolicyProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  policyContent,
  ...props
}) => {
  const outerClasses = classNames(
    'career section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const langContent = [{ __html: policyContent?.[0].contentEn }, { __html: policyContent?.[0].contentLt }];
  const [currentLang, setLanguage] = useState(langContent[0]);

  const handleLangChange = (index: number) => {
    setLanguage(langContent[index]);
  };

  return (
    <section id="career-section" {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="container">
          <div className="d-flex justify-content-end">
            <Button color="primary" onClick={() => handleLangChange(0)} tag={'a'}>
              EN
            </Button>
            <Button color="primary" onClick={() => handleLangChange(1)} tag={'a'}>
              LT
            </Button>
          </div>
        </div>

        <div className="container" dangerouslySetInnerHTML={currentLang}></div>
      </div>
    </section>
  );
};

PrivacyPolicy.defaultProps = defaultProps;

export default PrivacyPolicy;
